import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Content,
  Reviews,
  CustomerReviewModal,
} from "@bluefin/components";
import { Header, List } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"about-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"about-page"}
        >
          <Content className={"about-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>About Us</Header>
            </Content.Markup>
            <Content.Markup width={16} isMarkdown={false}>
              <div>
                <div>
                  <p>
                    Specializing in installation, repair and service of water
                    heaters, boilers and combination boilers.
                  </p>
                  <List
                    items={[
                      ">24 hour service",
                      ">Emergency on call",
                      ">Consulting",
                      ">Free estimates",
                      ">Commercial and Residential",
                      ">Licensed and Insured",
                    ]}
                  />
                  <p>
                    Our mission is provide a quality install or service to your
                    home or business at cost that is affordable and fair for the
                    work done. We do the job right the first time and strive for
                    perfection the first time. We take pride in our work and in
                    our customers.
                  </p>
                </div>
              </div>
            </Content.Markup>
          </Content>
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Customer Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <CustomerReviewModal
              businessId={fishermanBusiness._id}
              locations={allFishermanBusinessLocation.nodes}
              businessType={fishermanBusiness.type}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        author
        text
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
